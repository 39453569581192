html,
body {
  background-color: white !important;
  font-family: Fontsfree Net Jeko, sans-serif !important;
}

h1 {
  font-size: 40px !important;
  font-weight: bolder !important;
}

h4 {
  font-size: 20px !important;
  font-weight: normal !important;
}

h4 .list-group-item {
  background-color: transparent !important;
  color: inherit !important;
}

.list-group-item {
  border: none !important;
}

nav[data-bs-theme='light'] {
  background-color: white !important;
  border-bottom: 3px solid black;
  position: relative;
  z-index: 200;
}

nav .navbar-nav .btn.btn-primary {
  color: white !important;
}

.btn.btn-link {
  border: none;
  color: red !important;
  font-weight: bold;
  text-decoration: none;
  vertical-align: baseline !important;
}

.btn.btn-primary {
  background-color: red !important;
  border: 2px solid black !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: white !important;
  font-weight: bold;
  padding: 10px 20px !important;
}

.btn.btn-secondary {
  background-color: #bbbbbb !important;
  border: 2px solid black !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: white !important;
  font-weight: bold;
  padding: 10px 20px !important;
}

.card {
  background-color: transparent !important;
  border: none !important;
  color: black;
}

.card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.card-footer,
.card-header {
  background-color: transparent;
  border-bottom: none;
  border-top: none;
  padding: 0px;
}

.card-title {
  margin: 0px;
}

.checkbox.true {
  background-color: #00ff00;
  border-radius: 10px;
  color: black !important;
}

.col.bordered {
  border: 5px solid black !important;
  border-radius: 50px;
}

.text-danger {
  color: red !important;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 24px !important;
    font-weight: bolder !important;
  }

  h4 {
    font-size: 16px !important;
    font-weight: bolder !important;
  }

  .introLogo {
    max-height: 16px;
  }

  .introQR {
    max-height: 30px;
  }
}
