.planViewHeaderCell {
  position: relative;
  overflow: visible;
}

.planViewHeaderCell .dragHandle {
  cursor: move;
  font-size: 0;
  height: 20px;
  line-height: 10px;
  max-width: 50%;
  position: absolute;
  right: calc(50% - 25px);
  text-align: center;
  top: 0px;
  vertical-align: middle;
  width: 50px;
  z-index: 10;
}

.planViewHeaderCell .dragHandle:hover {
  background-color: rgba(0, 0, 0, 0.25);
  font-size: inherit;
}

.planViewHeaderCell.dragOverLeft .dropRightIndicator {
  background-color: rgba(20, 89, 124, 0.5);
  cursor: col-resize;
  height: 100vh;
  position: absolute;
  right: -3px;
  top: 0px;
  width: 6px;
  z-index: 10;
}

.planViewHeaderCell.dragOverRight .dropLeftIndicator {
  background-color: rgba(20, 89, 124, 0.5);
  cursor: col-resize;
  height: 100vh;
  position: absolute;
  left: -3px;
  top: 0px;
  width: 6px;
  z-index: 10;
}

.planViewHeaderCell .resizeHandle {
  cursor: col-resize;
  height: 100%;
  position: absolute;
  right: -3px;
  top: 0px;
  width: 6px;
  z-index: 10;
}

.planViewHeaderCell .resizeHandle:hover {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
}
